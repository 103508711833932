import * as React from 'react'
import parse from 'html-react-parser'
import './styles.scss'

const VoucherDisclaimer = ({voucherDisclaimer}) => {

    return (
        <div className="c-disclaimer">

            <p className="c-disclaimer__asterisks">
                {parse(voucherDisclaimer)}
            </p>
        </div>
    )
}

export default VoucherDisclaimer
